import {useState, useEffect, useCallback, useRef} from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import NoSleep from 'nosleep.js';
import './App.css';

function App() {
  const [deviceId, setDeviceId] = useState(null);
  const [name, setName] = useState('');
  const [inputName, setInputName] = useState('');
  const [siteName, setSiteName] = useState('');
  const [inputSiteName, setInputSiteName] = useState('');
  const [tracking, setTracking] = useState(false);
  const geoLocation = useRef(navigator.geolocation);
  const [locationWatchId, setLocationWatchId] = useState(null);
  const [location, setLocation] = useState({longitude: 0, latitude: 0 });
  const updateTimer = useRef(null);
  const [siteBuffer, setSiteBuffer] = useState([]);
  const [geoBuffer, setGeoBuffer] = useState([]);
  const noSleep = useRef(new NoSleep());
  
  const deviceIdRef = useRef(deviceId);
  const nameRef = useRef(name);
  const siteNameRef = useRef(siteName);
  const locationRef = useRef(location);
  const siteBufferRef = useRef(siteBuffer);
  const geoBufferRef = useRef(geoBuffer);

  deviceIdRef.current = deviceId;
  nameRef.current = name;
  siteNameRef.current = siteName;
  locationRef.current = location;
  siteBufferRef.current = siteBuffer;
  geoBufferRef.current = geoBuffer;

  useEffect(() => {
    const storedDeviceId = localStorage.getItem('device_id');
    if(storedDeviceId) {
      setDeviceId(storedDeviceId);
    } else {
      const newDeviceId = uuidv4();
      localStorage.setItem('device_id', newDeviceId);
      setDeviceId(newDeviceId);
    }
  }, [setDeviceId]);

  const handleNameInput = useCallback((event) => {
    setInputName(event.target.value);
  }, [setInputName])

  const handleNameUpdate = useCallback(() => {
    setName(inputName);
  }, [setName, inputName]);

  const handleSiteNameInput = useCallback((event) => {
    setInputSiteName(event.target.value);
  }, [setInputSiteName])

  const handleSiteNameUpdate = useCallback(() => {
    setSiteName(inputSiteName);
  }, [setSiteName, inputSiteName]);

  const handleLocationUpdate = useCallback((position) => {
    // console.log(position);
    setLocation({longitude: position.coords.longitude, latitude: position.coords.latitude});
  }, [setLocation]);

  const handleLocationError = useCallback((error) => {
    // console.log(error);
    setLocation({longitude: 0, latitude: 0});
  }, [setLocation]);

  const sendUpdate = useCallback(() => {
    const gps = locationRef.current.longitude !== 0 && locationRef.current.latitude !== 0;
    const currentLocation = locationRef.current;
    const d = new Date();
    const dateString = d.getFullYear() + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + "T" + ("0" + d.getHours()).slice(-2) + ":" + ("0" + d.getMinutes()).slice(-2) + ":" + ("0" + d.getSeconds()).slice(-2);
    const siteName = siteNameRef.current || 'None';

    axios.post('https://signaltestapi.c.dev.marmelodigital.com/api/geolocations', {
      name: nameRef.current,
      uuid: deviceIdRef.current,
      longitude: currentLocation.longitude,
      latitude: currentLocation.latitude,
      logged_at: dateString,
      signal: 1,
      gps: gps
    })
    .then((response) => {
      geoBufferRef.current.forEach(buffer => {
        axios.post('https://signaltestapi.c.dev.marmelodigital.com/api/geolocations', {...buffer, signal: 0}).then(() => {}).catch(() => {}).finally(() => {
          setGeoBuffer([]);
        });
      });
    })
    .catch((response) => {
      const newGeoBuffer = geoBufferRef.current;
      newGeoBuffer.push({
        name: nameRef.current,
        uuid: deviceIdRef.current,
        longitude: currentLocation.longitude,
        latitude: currentLocation.latitude,
        logged_at: dateString,
        signal: 0,
        gps: gps
      });
      setGeoBuffer(newGeoBuffer);
    });

    axios.post('https://signaltestapi.c.dev.marmelodigital.com/api/sitelocations', {
      name: nameRef.current,
      uuid: deviceIdRef.current,
      site_name: siteName,
      logged_at: dateString,
      signal: 1,
    })
    .then((response) => {
      siteBufferRef.current.forEach(buffer => {
        axios.post('https://signaltestapi.c.dev.marmelodigital.com/api/sitelocations', {...buffer, signal: 0}).then(() => {}).catch(() => {}).finally(() => {
          setSiteBuffer([]);
        });
      });
    })
    .catch((response) => {
      const newSiteBuffer = siteBufferRef.current;
      newSiteBuffer.push({
        name: nameRef.current,
        uuid: deviceIdRef.current,
        site_name: siteName,
        logged_at: dateString,
        signal: 0,
      });
      setSiteBuffer(newSiteBuffer);
    });
  }, []);

  const handleStartTracking = useCallback(() => {
    setTracking(true);
    noSleep.current.enable();
    const options = {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: 0
    };
    const watchId = geoLocation.current.watchPosition(handleLocationUpdate, handleLocationError, options);
    setLocationWatchId(watchId);
    if(updateTimer.current !== null) {
      clearInterval(updateTimer.current);
    }
    updateTimer.current = setInterval(sendUpdate, 30000);
  }, [setTracking, sendUpdate, handleLocationUpdate, handleLocationError]);

  const handleStopTracking = useCallback(() => {
    setTracking(false);
    noSleep.current.disable();
    geoLocation.current.clearWatch(locationWatchId);
  }, [locationWatchId]);

  return (
    <div className="App">
            <div className="container">
              <div className="header">Instructions</div>
              <div>
                <p>Enter your name (so we know whose phone we're tracking) and a location name (e.g. 'Museum entrance').</p>
                <p>Then press 'Start tracking'.  Make sure you allow the page access to your location when asked.  Your phone should stay awake while tracking.</p>
                <p>Update the location name whenever you move to a new location.</p>
                <p>Remember to stop tracking once you're done.</p>
              </div>
            </div>
            <div className="container">
              <div className="header">Device Id</div>
              <div>{deviceId}</div>
            </div>
            <div className="container">
              <div className="header">Your name:</div>
              <div className="value">{name}</div>
              <div><input onChange={handleNameInput} value={inputName} /><button onClick={handleNameUpdate}>Set name</button></div>
            </div>
            <div className="container">
              <div className="header">Where are you currently:</div>
              <div className="value">{siteName}</div>
              <td><input onChange={handleSiteNameInput} value={inputSiteName} /><button onClick={handleSiteNameUpdate}>Set location</button></td>
            </div>
            <div className="container">
              <div className="header">Position:</div>
              <div className="value">Longitude {location.longitude}</div>
              <div className="value">Latitude {location.latitude}</div>
            </div>
            <div className="container">
              <div>
                {!tracking && <button className="fullWidth" onClick={handleStartTracking} disabled={!name || !deviceId}>Start Tracking</button>}
                {tracking && <button className="fullWidth" onClick={handleStopTracking}>Stop Tracking</button>}
              </div>
            </div>

    </div>
  );
}

export default App;
